import React, { useRef } from "react";
import "./WhyChooseUs.css";
import { motion, useScroll, useTransform } from "framer-motion";
import {handleRegisterClick} from "./Common";

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2
    }
  }
};

const cardVariants = {
  hidden: { 
    opacity: 0, 
    x: -100 
  },
  visible: { 
    opacity: 1, 
    x: 0,
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 24
    }
  }
};

const features = [
  {
    icon: "⚙️",
    title: "Industry-Experienced Faculties",
    description: "",
  },
  {
    icon: "💻",
    title: "Micro Internships",
    description: "",
  },
  {
    icon: "🕒",
    title: "Online & Offline Batches",
    description: "",
  },
  // {
  //   icon: "📚",
  //   title: "Easy-to-Understand Study Materials",
  //   description: "",
  // },
  
  // {
  //   icon: "🤝",
  //   title: "On-Job Training and 100% Placement Support Program",
  //   description: "",
  // },
];

const WhyChooseUs = () => {
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start start", "end start"]
  });

  
  const scale = useTransform(scrollYProgress, [0, 0.5], [1, 0.9]);
  const opacity = useTransform(scrollYProgress, [0, 0.5], [1, 0.7]);
  const translateY = useTransform(scrollYProgress, [0, 0.5], [0, 100]);

  return (
    <div ref={ref} className="why-choose-us-container">
      <motion.section 
        className="why-choose-us"
        style={{
          scale,
          opacity,
          translateY,
          position: 'sticky',
          top: 0,
          zIndex: 10,
        }}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={containerVariants}
      >
        <motion.h2
          className="title"
          initial={{ opacity: 0, y: -50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: false }}
          transition={{ duration: 0.6 }}
        >
          Why Choose LilaQ.Ai
        </motion.h2>

        <motion.p
          className="subtitle"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: false }}
          transition={{ duration: 0.6 }}
        >
           Stay ahead with this transformative technology and secure exciting opportunities in AI development, creative automation, and beyond.
        </motion.p>

        <motion.div
          className="features-grid"
          variants={containerVariants}
        >
          {features.map((feature, index) => (
            <motion.div
              key={index}
              className="feature-card"
              variants={cardVariants}
              initial="hidden"
              whileInView="visible"
              viewport={{ 
                once: false, 
                amount: 0.1   
              }}
              transition={{ 
                delay: index * 0.2, 
                duration: 0.6 
              }}
            >
              <div className="icon">{feature.icon}</div>
              <h3 className="feature-title">{feature.title}</h3>
              {feature.description && (
                <p className="feature-description">{feature.description}</p>
              )}
            </motion.div>
          ))}
        </motion.div>

        <motion.button
          className="cta-button"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: false }}
          whileHover={{
            scale: 1.05,
            transition: { duration: 0.2 }
          }}
          whileTap={{ scale: 0.95 }}
          onClick={handleRegisterClick}
        >
          Know More →
        </motion.button>
      </motion.section>
    </div>
  );
};

export default WhyChooseUs;
