import React, { useState, useEffect } from "react";
import Confetti from "react-confetti";
import {
  Box,
  Button,
  Stepper,
  Step,
  StepLabel,
  TextField,
  MenuItem,
  Typography,
  CircularProgress,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Razorpay } from "razorpay"; // Import Razorpay SDK

const courses = [
  { name: "Full Stack Development", amount: 33750, instalment: 12500 },
  { name: "AI and Machine Learning & Power BI", amount: 49050, instalment: 10900 },
  { name: "Gen AI and Prompt Engineering", amount: 53150, instalment: 19700 },
  { name: "Big Data Analytics", amount: 35640, instalment: 13200 },
  { name: "Mastering Cloud Resources (Azure & AWS)", amount: 40500, instalment: 15000 },
  { name: "Software Testing Training - Manual and Automation", amount: 31050, instalment: 11500 },
  { name: "Mastering UI/UX", amount: 31050, instalment: 11500 },
  { name: "Flutter Development", amount: 29430, instalment: 10900 },  
  // { name: "Flutter Development", amount: 1, instalment: 1 },  
];

const RegistrationStepper = ({ open, onClose, initialCourse, amount }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [paymentSuccessful, setPaymentSuccessful] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    selectedCourse: initialCourse,
    paymentType: "",
    amount: amount
  });
  const [loading, setLoading] = useState(false);
  const [loadingFull, setLoadingFull] = useState(false);
  const [loadingInstalment, setLoadingInstalment] = useState(false);
  const [validations, setValidations] = useState({ name: false, email: false, phone: false });
  const [isPhoneValid, setIsPhoneValid] = useState(true);

  const steps = ["Enter Details", "Select Course", "Make Payment"];
  useEffect(() => {
    const selected = courses.find((course) => course.name === formData.selectedCourse);
    if (selected) {
      setFormData((prev) => ({ ...prev, amount: selected.amount }));
      setFormData((prev) => ({ ...prev, instalment: selected.instalment }));
    }
  }, [formData.selectedCourse]);

  const phoneRegex = /^[7-9]\d{9}$/; // Regular expression for Indian phone numbers

  const handleNext = () => {
    const isValid =
      formData.name &&
      formData.email &&
      formData.phone &&
      isPhoneValid; // Ensure phone number is valid

    if (!isValid) {
      setValidations({
        name: !formData.name,
        email: !formData.email,
        phone: !formData.phone || !isPhoneValid,
      });
      return; // Prevent moving to the next step if validation fails
    }

    setActiveStep((prev) => prev + 1);
  };

  const validatePhone = (phone) => {
    const isValid = phoneRegex.test(phone);
    setIsPhoneValid(isValid);
    return isValid;
  };

  const handleBack = () => setActiveStep((prev) => prev - 1);

  const handleChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const calculateDiscountedPrice = (amount, discount = 0.10) => {
    return (amount - amount * discount).toFixed(2);
  };

  const handlePaymentFull = async () => {
    setLoadingFull(true);
    formData.paymentType = 'Full Payment';

    // Razorpay Order Creation - Backend API call
    const order = await fetch("/api/create-order", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ amount: formData.amount }), // Replace with dynamic amount
    }).then((res) => res.json());

    const options = {
      key: "rzp_live_rbQ2xJhlF7uvFQ", // Replace with Razorpay API Key
      amount: order.amount,
      currency: "INR",
      name: formData.name,
      description: `Course: ${formData.selectedCourse}`,
      order_id: order.id,
      handler: async (response) => {
        // alert("Payment successful!");
        console.log(response); // Log response for validation
        await fetch("/api/save-registration", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formData),
        });

        // alert("Payment and registration successful!");
        setPaymentSuccessful(true);
        setLoadingFull(false);
        setActiveStep(3);
        setOpenSuccessDialog(true);
        // setActiveStep(0);
        // onClose();
      },
      prefill: {
        name: formData.name,
        email: formData.email,
        contact: formData.phone,
      },
      theme: { color: "#3399cc" },
      method: {
        netbanking: true,
        card: true,
        upi: true,
        wallet: false,
      },
      modal: {
        ondismiss: () => {
          alert("Payment was cancelled!");
          setLoadingFull(false);
        },
      },
    };

    const razorpay = new window.Razorpay(options);
    razorpay.open();
  };


  const handlePaymentInstalment = async () => {
    setLoadingInstalment(true);
    formData.paymentType = 'Instalment';

    // Razorpay Order Creation - Backend API call
    const order = await fetch("/api/create-order", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ amount: formData.instalment }), // Replace with dynamic amount
    }).then((res) => res.json());

    const options = {
      key: "rzp_live_rbQ2xJhlF7uvFQ", // Replace with Razorpay API Key
      amount: order.amount,
      currency: "INR",
      name: formData.name,
      description: `Course: ${formData.selectedCourse}`,
      order_id: order.id,
      handler: async (response) => {
        // alert("Payment successful!");
        console.log(response); // Log response for validation
        await fetch("/api/save-registration", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formData),
        });

        // alert("Payment and registration successful!");
        setPaymentSuccessful(true);
        setLoadingInstalment(false);
        setActiveStep(3); 
        setOpenSuccessDialog(true);
      },
      prefill: {
        name: formData.name,
        email: formData.email,
        contact: formData.phone,
      },
      method: {
        netbanking: true,
        card: true,
        upi: true,
        wallet: false,
      },
      
      theme: { color: "#3399cc" },
      modal: {
        ondismiss: () => {
          alert("Payment was cancelled!");
          setLoadingInstalment(false);
        },
      },
    };

    const razorpay = new window.Razorpay(options);
    razorpay.open();
  };

  const handleCloseSuccessDialog = () => {
    setOpenSuccessDialog(false);
  };

  return (
    <Box sx={{ p: 4 }}>
      <IconButton
        onClick={onClose}
        sx={{ position: "absolute", top: 8, right: 8 }}
      >
        <CloseIcon />
      </IconButton>
      <Typography variant="h5" mb={2} align="center">
        Registration Process
      </Typography>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Box sx={{ mt: 4 }}>
        {activeStep === 0 && (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <TextField
              label="Name"
              fullWidth
              value={formData.name}
              onChange={(e) => handleChange("name", e.target.value)}
              required
              error={validations.name}
              helperText={validations.name ? "Name is required" : ""}
            />
            <TextField
              label="Email"
              fullWidth
              value={formData.email}
              onChange={(e) => handleChange("email", e.target.value)}
              required
              error={validations.email}
              helperText={validations.email ? "Email is required" : ""}
            />
            <TextField
              label="Phone"
              fullWidth
              value={formData.phone}
              onChange={(e) => {
                const phone = e.target.value;
                handleChange("phone", phone);
                validatePhone(phone);
              }}
              required
              error={validations.phone}
              helperText={
                validations.phone
                  ? !isPhoneValid
                    ? "Invalid phone number. Must be 10 digits."
                    : "Phone is required"
                  : ""
              }
            />
          </Box>
        )}

        {activeStep === 1 && (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            <TextField
              select
              label="Select Course"
              fullWidth
              value={formData.selectedCourse}
              onChange={(e) => handleChange("selectedCourse", e.target.value)}
              disabled
            >
              {courses.map((course, index) => (
                <MenuItem key={index} value={course.name}>
                  {course.name}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        )}

        {activeStep === 2 && (
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h6" mb={2}>
              Course: {formData.selectedCourse}
            </Typography>
            <Typography variant="body1" mb={4}>
              Pay Full Amount (10% Off): <span style={{color: 'green'}}>₹{formData.amount}</span>
            </Typography>
            <Typography variant="body1" mb={2}>
              Pay 1st Instalment: ₹{formData.instalment}
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
              <Button
                variant="contained"
                color="primary"
                disabled={loadingInstalment || loadingFull}
                onClick={handlePaymentFull}
                fullWidth
              >
                {loadingFull ? <CircularProgress size={24} /> : "Proceed to Pay in Full"}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                disabled={loadingInstalment || loadingFull}
                onClick={handlePaymentInstalment}
                fullWidth
              >
                {loadingInstalment ? <CircularProgress size={24} /> : "Proceed to Pay 1st Instalment"}
              </Button>
            </Box>
          </Box>
        )}
        {activeStep === 3 && paymentSuccessful && (
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h6" mb={2}>
              Registration Complete!
            </Typography>
            <Typography variant="body1" mb={4}>
              Your registration has been successfully completed. Thank you for your payment.
            </Typography>
            <Button variant="contained" color="primary" onClick={onClose}>
              Close
            </Button>
          </Box>
        )}
      </Box>

      <Box sx={{ mt: 4, display: "flex", justifyContent: "space-between" }}>
        <Button
          variant="outlined"
          disabled={activeStep === 0}
          onClick={handleBack}
        >
          Back
        </Button>
        {activeStep < steps.length - 1 && (
          <Button variant="contained" onClick={handleNext}>
            Next
          </Button>
        )}
      </Box>
      <Dialog
      open={openSuccessDialog}
      aria-labelledby="success-dialog-title"
      aria-describedby="success-dialog-description"
      onClose={handleCloseSuccessDialog}
      maxWidth="md"
      fullWidth
    >
      <DialogContent sx={{ position: "relative", textAlign: "center", p: 4}}>
        {/* Confetti Animation */}
        {openSuccessDialog && <Confetti width={window.innerWidth} height={window.innerHeight} numberOfPieces={100} />}

        {/* Party Popper Icon (Could be an emoji or a custom icon) */}
        <Typography variant="h3" color="primary" sx={{ mb: 2 }}>
          🎉
        </Typography>

        <Typography variant="h5" align="center" color="success.main" sx={{ fontWeight: 'bold', mb: 2 }}>
          Payment Successful!
        </Typography>

        <DialogContentText id="success-dialog-description" sx={{ mb: 3 }}>
          <Typography variant="body1">
            Your payment has been successfully processed. Thank you for choosing LilaQ!
          </Typography>
          <Typography variant="body2" sx={{ mt: 2, color: 'text.secondary' }}>
            You will receive a confirmation email soon. Stay tuned!
          </Typography>
        </DialogContentText>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "center", pb: 3 }}>
        <Button
          onClick={handleCloseSuccessDialog}
          variant="contained"
          color="primary"
          sx={{ textTransform: 'none', fontWeight: 'bold', px: 5 }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
    </Box>
  );
};

export default RegistrationStepper;
