import React, { useState } from "react";
import "./UserDetailsForm.css";

const UserDetailsForm = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    education: "",
  });

  const [errors, setErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName.trim()) newErrors.firstName = "First name is required.";
    if (!formData.lastName.trim()) newErrors.lastName = "Last name is required.";
    if (!formData.email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/))
      newErrors.email = "Please enter a valid email address.";
    if (!formData.phone.match(/^\d{10}$/))
      newErrors.phone = "Please enter a 10-digit phone number.";
    if (!formData.education.trim()) newErrors.education = "Education is required.";
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length === 0) {
      console.log("Form submitted successfully:", formData);
      alert("Form submitted successfully!");

      // Send data to Google Sheets via Google Form
      const googleFormURL = "https://docs.google.com/forms/d/e/1jWNHYSTgVFNMPEWlmmdWb578IJ7VcTIl6I6vmecSqbs/formResponse"; // Replace with your form's action URL
      const formDataToSubmit = new FormData();
      formDataToSubmit.append("entry.1796768538", formData.firstName); // Replace with the correct entry ID for first name
      formDataToSubmit.append("entry.633732878", formData.lastName); // Replace with the correct entry ID for last name
      formDataToSubmit.append("entry.1012370152", formData.email); // Replace with the correct entry ID for email
      formDataToSubmit.append("entry.4053313124", formData.phone); // Replace with the correct entry ID for phone
      formDataToSubmit.append("entry.475598217", formData.education); // Replace with the correct entry ID for education

      fetch(googleFormURL, {
        method: "POST",
        body: formDataToSubmit,
      })
        .then(() => {
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            education: "",
          });
        })
        .catch((error) => {
          console.error("Error submitting the form:", error);
        });
    } else {
      setErrors(validationErrors);
    }
  };

  return (
    <div className="user-details-form">
      <h2>Register Your Details</h2>
      <form onSubmit={handleSubmit}>
        {/* First Name */}
        <div className="form-group">
          <label htmlFor="firstName">First Name</label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={handleInputChange}
            placeholder="Enter your first name"
          />
          {errors.firstName && <small className="error">{errors.firstName}</small>}
        </div>

        {/* Last Name */}
        <div className="form-group">
          <label htmlFor="lastName">Last Name</label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={handleInputChange}
            placeholder="Enter your last name"
          />
          {errors.lastName && <small className="error">{errors.lastName}</small>}
        </div>

        {/* Email */}
        <div className="form-group">
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            placeholder="Enter your email"
          />
          {errors.email && <small className="error">{errors.email}</small>}
        </div>

        {/* Phone */}
        <div className="form-group">
          <label htmlFor="phone">Phone Number</label>
          <input
            type="tel"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleInputChange}
            placeholder="Enter your phone number"
          />
          {errors.phone && <small className="error">{errors.phone}</small>}
        </div>

        {/* Education */}
        <div className="form-group">
          <label htmlFor="education">Education</label>
          <input
            type="text"
            id="education"
            name="education"
            value={formData.education}
            onChange={handleInputChange}
            placeholder="Enter your latest degree"
          />
          {errors.education && <small className="error">{errors.education}</small>}
        </div>

        <button type="submit" className="submit-button">Submit</button>
      </form>
    </div>
  );
};

export default UserDetailsForm;
