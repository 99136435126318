import React, { useEffect, useRef } from 'react';
import { motion } from "framer-motion";
import "./FullStackTimeline.css";
import { 
  Timeline, 
  TimelineItem, 
  TimelineSeparator, 
  TimelineConnector, 
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent
} from '@mui/lab';

const FullStackTimeline = () => {
  const timelineRef = useRef(null);  

 
  const handleScroll = () => {
    if (!timelineRef.current) return;
    const timeline = timelineRef.current;

    const timelineDots = timeline.querySelectorAll('.MuiTimelineDot-root');
    const timelineConnectors = timeline.querySelectorAll('.MuiTimelineConnector-root');
    const timelineContents = timeline.querySelectorAll('.timeline-content');
    
    const scrollPosition = window.scrollY;
    const timelineTop = timeline.offsetTop;
    const timelineHeight = timeline.offsetHeight;

    const scrollPercentage = (scrollPosition - timelineTop) / timelineHeight;
    const highlightHeight = Math.min(Math.max(scrollPercentage * 100, 0), 100);

    const scrollHighlight = timeline.querySelector('.scroll-highlight'); // Ensure you have a scroll highlight element
    if (scrollHighlight) {
      scrollHighlight.style.setProperty('--scroll-height', `${highlightHeight}%`);
    }

    timelineDots.forEach((dot, index) => {
      const dotTop = dot.getBoundingClientRect().top;
      if (dotTop < window.innerHeight * 0.7 && dotTop > 0) {
        dot.classList.add('active');
        timelineConnectors[index]?.classList.add('active');
        timelineContents[index]?.classList.add('active');
      } else {
        dot.classList.remove('active');
        timelineConnectors[index]?.classList.remove('active');
        timelineContents[index]?.classList.remove('active');
      }
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div>
      
      <section className="fullstack-timeline">
        
        <motion.div
          className="timeline-header left"
          initial={{ x: -100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <h1>Full Stack Development (4 months)</h1>
        </motion.div>

        
        <motion.div
          className="timeline"
          initial={{ x: 200, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.8, delayChildren: 0.2 }}
        >
          <Timeline>
      
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot sx={{ 
                    borderColor: '#ffd700',
                    borderWidth: 2,
                    background: 'transparent',
                    boxShadow: '0 0 10px rgba(255, 215, 0, 0.3)',
                    '&.active': {
                      borderColor: 'white',
                      boxShadow: '0 0 20px rgba(255, 255, 255, 0.7)'
                    }
                  }} />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <div className="timeline-item">
              <div className="timeline-content">
                <h2>Front-End Development</h2>
                <p>
                  Master HTML, CSS, and JavaScript to create visually appealing and responsive user interfaces.
                </p>
              </div>
            </div>
          </TimelineContent>
        </TimelineItem>

      
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot sx={{ 
                    borderColor: '#ffd700',
                    borderWidth: 2,
                    background: 'transparent',
                    boxShadow: '0 0 10px rgba(255, 215, 0, 0.3)',
                    '&.active': {
                      borderColor: 'white',
                      boxShadow: '0 0 20px rgba(255, 255, 255, 0.7)'
                    }
                  }} />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <div className="timeline-item">
              <div className="timeline-content">
                <h2>Back-End Development</h2>
                <p>
                  Learn server-side programming languages and frameworks to build robust, scalable applications.
                </p>
              </div>
            </div>
          </TimelineContent>
        </TimelineItem>

        
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot sx={{ 
                    borderColor: '#ffd700',
                    borderWidth: 2,
                    background: 'transparent',
                    boxShadow: '0 0 10px rgba(255, 215, 0, 0.3)',
                    '&.active': {
                      borderColor: '#ffffff',
                      boxShadow: '0 0 20px rgba(255, 255, 255, 0.7)'
                    }
                  }} />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <div className="timeline-item">
              <div className="timeline-content">
                <h2>Full-Stack Integration</h2>
                <p>
                  Combine front-end and back-end skills to develop seamless, end-to-end web solutions.
                </p>
              </div>
            </div>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
        </motion.div>
      </section>

     

      <section className="fullstack-timeline">
        
        <motion.div
          className="timeline-header right"
          initial={{ x: 200, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <h1>Flutter Development (3 months)</h1>
        </motion.div>

        
        <motion.div
          className="timeline"
          initial={{ x: -200, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.8, delayChildren: 0.2 }}
        >
          <Timeline>
          
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot sx={{ 
                    borderColor: '#ffd700',
                    borderWidth: 2,
                    background: 'transparent',
                    boxShadow: '0 0 10px rgba(255, 215, 0, 0.3)',
                    '&.active': {
                      borderColor: 'white',
                      boxShadow: '0 0 20px rgba(255, 255, 255, 0.7)'
                    }
                  }}>
                
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <div className="timeline-item">
                  <div className="timeline-content">
                    <h2>Dart Programming</h2>
                    <p>
                      Gain proficiency in the Dart programming language, the foundation of Flutter development.
                    </p>
                  </div>
                </div>
              </TimelineContent>
            </TimelineItem>

        
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot sx={{ 
                    borderColor: '#ffd700',
                    borderWidth: 2,
                    background: 'transparent',
                    boxShadow: '0 0 10px rgba(255, 215, 0, 0.3)',
                    '&.active': {
                      borderColor: 'white',
                      boxShadow: '0 0 20px rgba(255, 255, 255, 0.7)'
                    }
                  }}>
                  
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <div className="timeline-item">
                  <div className="timeline-content">
                    <h2>Flutter Fundamentals</h2>
                    <p>
                      Understand the core concepts and widgets of the Flutter framework for building beautiful, high-performance mobile apps.
                    </p>
                  </div>
                </div>
              </TimelineContent>
            </TimelineItem>

        
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot sx={{ 
                    borderColor: '#ffd700',
                    borderWidth: 2,
                    background: 'transparent',
                    boxShadow: '0 0 10px rgba(255, 215, 0, 0.3)',
                    '&.active': {
                      borderColor: 'white',
                      boxShadow: '0 0 20px rgba(255, 255, 255, 0.7)'
                    }
                  }}>
                 
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <div className="timeline-item">
                  <div className="timeline-content">
                    <h2>App Development</h2>
                    <p>
                      Develop and deploy cross-platform mobile applications for both iOS and Android.
                    </p>
                  </div>
                </div>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
        
        </motion.div>
      </section>

       
      <section className="fullstack-timeline">
        
        <motion.div
          className="timeline-header left"
          initial={{ x: -100, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ duration: 1 }}
        >
          <h1>AI & Machine Learning (4 months)</h1>
        </motion.div>

        
        <motion.div
          className="timeline"
          initial={{ x: 200, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 0.8, delayChildren: 0.2 }}
        >
          <Timeline>
           
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot sx={{ 
                    borderColor: '#ffd700',
                    borderWidth: 2,
                    background: 'transparent',
                    boxShadow: '0 0 10px rgba(255, 215, 0, 0.3)',
                    '&.active': {
                      borderColor: 'white',
                      boxShadow: '0 0 20px rgba(255, 255, 255, 0.7)'
                    }
                  }}>
                 
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <div className="timeline-item">
                  <div className="timeline-content">
                    <h2>Fundamentals of AI</h2>
                    <p>
                      Explore the core concepts and techniques of artificial intelligence, including machine learning, deep learning, and natural language processing.
                    </p>
                  </div>
                </div>
              </TimelineContent>
            </TimelineItem>

        
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot sx={{ 
                    borderColor: '#ffd700',
                    borderWidth: 2,
                    background: 'transparent',
                    boxShadow: '0 0 10px rgba(255, 215, 0, 0.3)',
                    '&.active': {
                      borderColor: 'white',
                      boxShadow: '0 0 20px rgba(255, 255, 255, 0.7)'
                    }
                  }}>
             
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <div className="timeline-item">
                  <div className="timeline-content">
                    <h2>Data Preprocessing</h2>
                    <p>
                      Learn how to gather, clean, and prepare data for effective machine learning model training.
                    </p>
                  </div>
                </div>
              </TimelineContent>
            </TimelineItem>

        
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot sx={{ 
                    borderColor: '#ffd700',
                    borderWidth: 2,
                    background: 'transparent',
                    boxShadow: '0 0 10px rgba(255, 215, 0, 0.3)',
                    '&.active': {
                      borderColor: 'white',
                      boxShadow: '0 0 20px rgba(255, 255, 255, 0.7)'
                    }
                  }}>
                
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <div className="timeline-item">
                  <div className="timeline-content">
                    <h2>Model Training & Deployment</h2>
                    <p>
                      Learn how to train machine learning models and deploy them for production use.
                    </p>
                  </div>
                </div>
              </TimelineContent>
            </TimelineItem>
          </Timeline>
          </motion.div>
       
      </section>
    </div>
  );
};

export default FullStackTimeline;




