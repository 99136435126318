import React, { useEffect, useState } from 'react';
import './PlacementSection.css';
import { useSpring, animated } from "@react-spring/web";
import { handleRegisterClick } from './Common';

const placementHighlights = [
  {
    title: 'Industry-Aligned Curriculum',
    description: 'Programs tailored to meet the skills demanded by top tech companies.',
    // additionalDescription: 'Stay ahead with a curriculum designed in collaboration with industry experts to ensure your skills are always in demand.'
  },
  {
    // title: '',
    title: 'Placement Readiness',
    description: 'Mock interviews, resume building, and communication skills training.',
    // additionalDescription: 'Our dedicated placement training ensures you’re fully prepared for real-world job interviews and career challenges.'
  },
  {
    title: 'Real-World Projects',
    description: 'Hands-on projects to help you gain practical, job-ready skills.',
    // additionalDescription: 'Work on real-life projects that give you the practical experience employers seek, while building your portfolio.'
  },
  {
    title: 'Career Guidance',
    description: 'Expert mentorship and personalized support for career growth.',
    // additionalDescription: 'Our career guidance experts provide one-on-one coaching to help you map out a clear path to success in your field.'
  },
  {
    title: 'Next Could Be You!',
    description: 'Join LilaQ AI Academy and transform your career trajectory.',
    // additionalDescription: 'Start your journey with LilaQ AI Academy today and position yourself for a bright, fulfilling career in the tech industry.'
  },
];

function PlacementSection() {
  const [isVisible, setIsVisible] = useState(new Array(placementHighlights.length).fill(false));

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const index = parseInt(entry.target.getAttribute('data-index'), 10);
          if (entry.isIntersecting) {
            setIsVisible((prev) => {
              const updated = [...prev];
              updated[index] = true;
              return updated;
            });
          }
        });
      },
      { threshold: 0.2 } // Trigger when 20% of the element is visible
    );

    const cards = document.querySelectorAll('.placement-card');
    cards.forEach((card) => observer.observe(card));

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <section className="placement-section">
      <div className="video-background">
        {/* <video autoPlay loop muted>
          <source src="/success.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video> */}
        <img src="/bg.jpg" alt="Background" width='100%' height='100%'/>
      </div>
      {/* Overlay div for dark effect */}
      <div className="overlay"></div>
      <div className="content-wrapper">
        <h2>Placement Assistance</h2>
        <p className="placement-description">
          LilaQ AI Academy empowers you with the skills and confidence to achieve your career goals.
        </p>
        <div className="placement-grid">
          {placementHighlights.map((highlight, index) => (
            <animated.div
              key={index}
              data-index={index}
              className="placement-card"
              style={{
                opacity: isVisible[index] ? 1 : 0,
                transform: isVisible[index] ? 'translateY(0)' : 'translateY(50px)',
                transition: 'opacity 0.6s ease, transform 0.6s ease',
                transitionDelay: `${index * 0.2}s`, // Stagger animation
              }}
            >
              {highlight.title === 'Next Could Be You!'?
              <h3 style={{color: '#ff9800'}}>{highlight.title}</h3>:
              <h3 style={{fontSize: '1.2rem', fontWeight: 600, margin: '10px 0 5px', color: '#ffffff'}}>{highlight.title}</h3>}
              <p>{highlight.description}</p>
              <p className="additional-info">{highlight.additionalDescription}</p>
            </animated.div>
          ))}
        </div>
        <div className="cta-section">
          <button className="cta-button" onClick={handleRegisterClick}>
            Book Placement Consultation
          </button>
        </div>
      </div>
    </section>
  );
}

export default PlacementSection;
