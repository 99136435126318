export const handleRegisterClick = () => {
    // setIsFormVisible(true);
    const googleFormURL = "https://forms.gle/shRpZ6rovvzvjwhA7"; // Replace with your actual Google Form link
  window.open(googleFormURL, "_blank");
  };


export const handleQuoteCorporate = () => {
  // setIsFormVisible(true);
  const googleFormURL = "https://forms.gle/e3mGsUFGx4HLdJpa9"; // Replace with your actual Google Form link
window.open(googleFormURL, "_blank");
};

export const handleQuoteCampus = () => {
  // setIsFormVisible(true);
  const googleFormURL = "https://forms.gle/dF1WMKDVy7DCiUzL7"; // Replace with your actual Google Form link
window.open(googleFormURL, "_blank");
};
