import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

const TypewriterEffect = () => {
  // const text = "Empowering Future Leaders Through Innovative Training and Skill Development";
  // const text = "Reserve your seats now!"
  const text = "Few Seats Remaining – Secure Yours Today!"
  const [displayedText, setDisplayedText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isBlinking, setIsBlinking] = useState(true);

  // Typing effect logic
  useEffect(() => {
    if (currentIndex < text.length) {
      const timeout = setTimeout(() => {
        setDisplayedText((prev) => prev + text[currentIndex]);
        setCurrentIndex((prev) => prev + 1);
      }, 100); // Speed of typing (in ms)
      return () => clearTimeout(timeout);
    } else {
      // Add delay before clearing text and restarting
      const restartDelay = setTimeout(() => {
        setDisplayedText("");
        setCurrentIndex(0);
      }, 2000); // Delay before restarting the typing effect
      return () => clearTimeout(restartDelay);
    }
  }, [currentIndex, text]);

  // Blinking cursor logic
  useEffect(() => {
    const cursorInterval = setInterval(() => {
      setIsBlinking((prev) => !prev);
    }, 500); // Blinking speed
    return () => clearInterval(cursorInterval);
  }, []);

  return (
    <motion.p
      className="typewriter-text"
      initial={{ y: 30, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ delay: 0.2, duration: 0.8 }}
      style={{
        fontFamily: "sans-serif",
        fontWeight: "bold",
        fontSize: "1.3rem",
        // whiteSpace: "nowrap",
        overflow: "hidden",
        display: "inline-block",
        whiteSpace: "normal", 
        wordBreak: "break-word",
        color:'#FCDC5A'
      }}
    >
      {displayedText}
      <span
        style={{
          display: "inline-block",
          width: "2px",
          backgroundColor: isBlinking ? "white" : "transparent",
          height: "1.5rem",
          marginLeft: "5px",
        }}
      ></span>
    </motion.p>
  );
};

export default TypewriterEffect;
