import React, { useState, useEffect } from "react";
import "./App.css";
import PlacementSection from "./PlacementSection";
import ExperienceSection from "./ExperienceSection";
import WhyChooseUs from "./WhyChooseUs";
import AOS from "aos";
import "aos/dist/aos.css";
import { motion, useScroll, useTransform } from "framer-motion";
import FullStackTimeline from "./FullStackTimeline";
import UserDetailsForm from "./UserDetailsForm";
import { Fab, useScrollTrigger, Zoom, Tooltip } from "@mui/material";
import { Modal, Backdrop, Fade, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Button } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import FlutterDevelopment from "./FlutterDevelopment";
import AIMachineLearning from "./AIMachineLearning";
import { Link, Element } from "react-scroll";
import { Box } from "@mui/material";
import { handleRegisterClick } from "./Common";
import TypewriterEffect from "./TypewriterEffect";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CallIcon from '@mui/icons-material/Call'
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import CoursesPage from "./CoursesPage";


function BackToTop() {
  const trigger = useScrollTrigger();

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Zoom in={trigger}>
      <Box
        sx={{
          position: "fixed",
          bottom: 16,
          right: 16,
          zIndex: 1000,
        }}
      >
        <Fab color="primary" size="medium" onClick={handleClick} aria-label="back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </Box>
    </Zoom>
  );
}

function App() {
  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const heroSection = document.querySelector('.hero-section');
      if (heroSection) {
        const heroBottom = heroSection.getBoundingClientRect().bottom;
        setIsSticky(heroBottom <= 0);
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  React.useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const handleDownloadBrochure = () => {
    const link = document.createElement("a");
    link.href = "/brochure.pdf";
    link.download = "LilaQAi_Academy_Brochure.pdf";
    link.click();
  };

  const { scrollY } = useScroll();

  const titleLogo = useTransform(scrollY, [0, 200], [0, -100]);
  const titleY = useTransform(scrollY, [0, 300], [0, -100]);
  const titleScale = useTransform(scrollY, [0, 300], [1, 1]);
  const titleOpacity = useTransform(scrollY, [0, 300], [1, 0]);

  const [isFormVisible, setIsFormVisible] = useState(false);

  const handleClosePopup = () => {
    setIsFormVisible(false);
  };

  return (
    <div className="app">
      {/* Hero Section with Background Video */}
      <header className="hero-section">
        <Fab
          style={{
            position: 'absolute',
            top: '89%',
            right: '15px',
            transform: 'translateY(-50%)',
            background: "rgba(255, 255, 255, 0.15)", // Semi-transparent white
            color: "#4c6ef5",
            width: 'auto', // Allow width to adjust to content
            height: 50,
            borderRadius: "12px", // Curved rectangle
            boxShadow: "0 4px 6px rgba(0,0,0,0.1)", // Reduced shadow for transparency
            zIndex: 10,
            transition: "all 0.3s ease",
            padding: '0 15px', // Add some horizontal padding
            display: 'flex',
            alignItems: 'center',
            gap: '8px', // Space between icon and text
          }}
          onClick={handleDownloadBrochure}
          aria-label="Download Brochure"
        >
          <DownloadIcon style={{ fontSize: 24, color: "#ffffff" }} />
          <span
            style={{
              color: '#ffffff',
              fontSize: '0.875rem',
              fontWeight: 500,
              fontFamily: 'Poppins, sans-serif', // Set a professional font
              textTransform: 'capitalize', // Capitalize the first letter of each word
            }}
          >
            Brochure
          </span>
        </Fab>

        <video className="hero-video" autoPlay loop muted>
          <source src="/training.mp4" type="video/mp4" />
          Your browser does not support HTML5 video.
        </video>

        <div className="hero-overlay">

          <motion.img
            src="/logo.png"
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            style={{
              y: titleY,
              scale: titleScale,
              opacity: titleOpacity,
            }}
            transition={{
              type: "spring",
              stiffness: 100,
            }}
          />

          <motion.h1
            className="hero-title"
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}

            style={{
              y: titleY,
              scale: titleScale,
              opacity: titleOpacity,

            }}
            transition={{
              type: "spring",
              stiffness: 100,
            }}
          >
            Welcome to LilaQ.Ai Academy
          </motion.h1>

          {/* <motion.h2
            className="hero-subtitle"
            initial={{ y: 30, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.8 }}
          >
            Empowering Future Leaders Through Innovative Training and Skill Development
          </motion.h2> */}


          <motion.p
            className="hero-description"
            initial={{ y: 30, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.4, duration: 0.8 }}
          >
            Your journey to becoming a tech leader starts here. Learn practical,
            real-world skills tailored for success.{" "}
            {/* <span 
    className="learn-more-link" 
    onClick={() => {
    const timelineSection = document.getElementById("timeline");
    if (timelineSection) {
      timelineSection.scrollIntoView({ behavior: "smooth" });
    }
  }}> */}
            <span
              className="learn-more-link"
              onClick={handleRegisterClick}>
              Need Guidance?
            </span>
          </motion.p>

          <TypewriterEffect />

          <motion.div
            className="cta-buttons"
            initial={{ y: 30, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.6, duration: 0.8 }}

          >
            <button className="cta-btn primary" onClick={() => {
              const timelineSection = document.getElementById("timeline");
              if (timelineSection) {
                timelineSection.scrollIntoView({ behavior: "smooth" });
              }
            }}>
              Explore Courses
            </button>
          </motion.div>
        </div>

        <Box
          sx={{
            position: "fixed",
            display: "flex",
            flexDirection: "column",
            gap: 1,
            bottom: 22,
            left: {
              xs: 16,
              sm: 24,
              md: 35
            },
            zIndex: 1000,
            transition: 'left 0.3s ease'
          }}
        >
          <Fab
            style={{
              backgroundColor: "#34b7f1", // Color for the phone icon button
              color: "#ffffff",
              width: {
                xs: 56,
                sm: 70,
              },
              height: {
                xs: 56,
                sm: 70,
              },
              borderRadius: "50%",
            }}
            size="large"
            href="tel:+918714258111" // Phone number to call
            target="_blank"
            aria-label="Phone"
          >
            <CallIcon
              style={{
                fontSize: {
                  xs: 32,
                  sm: 39,
                  md: 48,
                  lg: 55,
                },
              }}
            />
          </Fab>
          <Fab
            style={{
              backgroundColor: "#25D366",
              color: "#ffffff",
              width: {
                xs: 56,
                sm: 70,

              },
              height: {
                xs: 56,
                sm: 70,
              },
              borderRadius: "50%"
            }}
            size="large"
            href="https://wa.me/+918714258111"
            target="_blank"
            aria-label="WhatsApp"
          >
            <WhatsAppIcon
              style={{
                fontSize: {
                  xs: 32,
                  sm: 39,
                  md: 48,
                  lg: 55
                }
              }}
            />
          </Fab>
        </Box>


        <Modal
          open={isFormVisible}
          onClose={handleClosePopup}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={isFormVisible}>
            <div className="modal-content" >
              <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                <IconButton
                  aria-label="close"
                  className="close-button"
                  onClick={handleClosePopup}
                >
                  <CloseIcon />
                </IconButton>
              </Box>

              <UserDetailsForm />
            </div>
          </Fade>
        </Modal>
      </header>

      {/* Navigation Links */}
      <nav className="navigation">
        {/* <Link to="benefits" smooth={true} duration={500}>
          Benefits
        </Link>
        <Link to="why-choose-us" smooth={true} duration={500}>
          Why Choose Us
        </Link>
        <Link to="placement" smooth={true} duration={500}>
          Placement
        </Link>
        <Link to="experience" smooth={true} duration={500}>
          Experience
        </Link> */}
        <Link to="timeline" smooth={true} duration={500}>

        </Link>
      </nav>

      {/* Full-page Sections */}
      {/* <Element name="benefits" >
        <section className="benefits-section" data-aos="fade-up">
          <h2>What LilaQ.Ai Academy Offers</h2>
          <div className="benefit-cards">
            <div className="benefit-card">
              <h3>Industry-Aligned Curriculum</h3>
              <p>
                Designed by experts, our courses are tailored to meet the
                demands of the tech industry.
              </p>
            </div>
            <div className="benefit-card">
              <h3>Project-Based Learning</h3>
              <p>
                Gain hands-on experience with real-world projects and practical
                case studies.
              </p>
            </div>
            <div className="benefit-card">
              <h3>Flexible & Accessible</h3>
              <p>
                Choose from flexible schedules and access learning resources
                anytime, anywhere.
              </p>
            </div>
          </div>
        </section>
      </Element> */}


      <Element name="why-choose-us">
        <WhyChooseUs />
      </Element>


      <Element name="placement">
        <PlacementSection />
      </Element>

      {/* Experience Section */}
      {/* <Element name="experience">
        <ExperienceSection />
      </Element> */}

      {/* Full Stack Timeline Section */}
      <Element name="timeline" id="timeline">
        <div className="timeline-section">
          {/* <FullStackTimeline /> */}
          {/* <FlutterDevelopment /> */}
          {/* <AIMachineLearning /> */}
        </div>
      </Element>

      <Element name="timeline" id="timeline">
        <div className="timeline-section">
          <CoursesPage />
          {/* <FlutterDevelopment />
          <AIMachineLearning /> */}
        </div>
      </Element>

      <BackToTop />

      {/* Footer */}
      {/* <footer className="footer">
        <p>© 2024 LilaQ.Ai Academy | All rights reserved.</p>
      </footer> */}

      <footer className="footer">
        <div className="footer-container" style={{display: 'none'}}>
          <div className="footer-section about">
            <h3>About LilaQ.Ai Academy</h3>
            <p>
              LilaQ.Ai Academy focuses on empowering future leaders
              through innovative training in technologies like Generative AI, Data Science & AI/ML, and
              Cloud Development. Our aim is to equip students with the skills they
              need to excel in their careers.
            </p>
            <div className="social-icons">
              <a href="#" target="_blank"><i className="fab fa-facebook-f"></i></a>
              {/* <a href="#" target="_blank"><i className="fab fa-x-twitter"></i></a> */}
              {/* <a href="#" target="_blank" rel="noopener noreferrer"><i className="fas fa-xmark"></i></a> */}
              <a href="#" target="_blank" rel="noopener noreferrer"><i className="fab fa-twitter"></i></a>
              <a href="#" target="_blank"><i className="fab fa-instagram"></i></a>
              <a href="#" target="_blank"><i className="fab fa-youtube"></i></a>
            </div>
          </div>
          <div className="footer-section links">
            <h3>Quick Links</h3>
            <ul>
              <li><a href="#how-to-apply">How to Apply</a></li>
              <li><a href="#registration">Registration</a></li>
              <li><a href="#career">Career</a></li>
              <li><a href="#faqs">FAQs</a></li>
              <li><a href="#gallery">Gallery</a></li>
            </ul>
          </div>
          <div className="footer-section pages">
            <h3>Pages</h3>
            <ul>
              <li><a href="#courses">Courses</a></li>
              <li><a href="#about-us">About Us</a></li>
              <li><a href="#results">Our Results</a></li>
              <li><a href="#centres">Centres</a></li>
              <li><a href="#contact">Contact Us</a></li>
            </ul>
          </div>
          <div className="footer-section contact">
            <h3>Contact</h3>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', alignItems: 'start' }}>
              <p style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                {/* <i className="fas fa-map-marker-alt" style={{ color: '#ff5722' }}></i>  */}
                <i className="fas fa-map-marker-alt"></i>
                near Info Park, Kakkanad, Kochi, Kerala - 682042
              </p>
              <p style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                {/* <i className="fas fa-phone" style={{ color: '#4caf50' }}></i>  */}
                <i className="fas fa-phone"></i>
                +91 8714258111
              </p>
              <p style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                {/* <i className="fas fa-envelope" style={{ color: '#2196f3' }}></i>  */}
                <i className="fas fa-envelope"></i>
                info@lilaq.ai
              </p>
            </div>

          </div>
        </div>
        <p className="footer-copyright">
            <h3>Contact</h3>
            <div >
              <p>
                {/* <i className="fas fa-map-marker-alt" style={{ color: '#ff5722' }}></i>  */}
                <i className="fas fa-map-marker-alt"></i>
                &nbsp; near Info Park, Kakkanad, Kochi, Kerala - 682042
              </p>
              <p >
                {/* <i className="fas fa-phone" style={{ color: '#4caf50' }}></i>  */}
                <i className="fas fa-phone"></i>
                &nbsp; <a href='tel:+91 8714258111' style={{textDecorationSkip:''}}>+91 8714258111</a>
               

              </p>
              <p >
                {/* <i className="fas fa-envelope" style={{ color: '#2196f3' }}></i>  */}
                <i className="fas fa-envelope"></i>
                &nbsp; <a href='mailto:info@lilaq.ai' style={{textDecoration:'none'}}>info@lilaq.ai</a>
              </p>
            </div>

          </p>
        <p className="footer-copyright">© 2024 LilaQ.Ai Academy | All rights reserved.</p>
      </footer>

    </div>
  );
}

export default App;

