import React, { useState } from 'react';

import {
  Box,
  Button,
  Card,
  CardContent,
  CardActions,
  Typography,
  IconButton,
  useMediaQuery,
  useTheme,
  Dialog
} from "@mui/material";
import Icon from "@mui/icons-material/School";
import "./CoursesPage.css";
import backgroundVideo from "./code.mp4";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { handleRegisterClick, handleQuoteCorporate, handleQuoteCampus } from './Common';
import RegistrationStepper from "./RegistrationStepper";


const courses = [
  {
    title: "Full Stack Development",
    icon: "School",
    nextBatch: "Starting December 2024",
    synopsis:
      "Learn MERN stack, RESTful APIs, and responsive web design to build powerful, scalable applications.",
    price: 37500,
    monthlyCost: (37500/ 3).toFixed(2),
    duration: "3 months",
    originalPrice: 47000,
    originalMonthlyCost: (47000 / 3).toFixed(2),
  },
  {
    title: "AI and Machine Learning & Power BI",
    icon: "Memory",
    nextBatch: "Starting December 2024",
    duration: "5 months",
    price: 54500,
    originalPrice: 65000,
    originalMonthlyCost: (65000 / 5).toFixed(2),
    monthlyCost: (54500 / 5).toFixed(2),
    synopsis:
      "Master neural networks, natural language processing, and machine learning frameworks like TensorFlow.",
  },
  {
    title: "Gen AI and Prompt Engineering",
    icon: "Chat",
    duration: "3 months",
    originalPrice: 75000,
    price: 59100,
    originalMonthlyCost: (75000 / 3).toFixed(2),
    monthlyCost: (59100 / 3).toFixed(2),
    nextBatch: "Starting December 2024",
    synopsis:
      "Learn advanced techniques for working with Generative AI, prompt engineering, and APIs like OpenAI.",
  },
  {
    title: "Big Data Analytics",
    icon: "Storage",
    price: 39600,
    nextBatch: "Starting December 2024",
    duration: "3 months",
    originalPrice: 50000,
    originalMonthlyCost: (50000 / 3).toFixed(2),
    monthlyCost: (39500 / 3).toFixed(2),
    synopsis:
      "Harness the power of big data tools like Hadoop, Spark, and Tableau for data-driven decision-making.",
  },
  
  {
    title: "Corporate Batch Trainings",
    icon: "Chat",
    duration: "1 month",
    originalPrice: 75000,
    price: 59100,
    originalMonthlyCost: (75000 / 3).toFixed(2),
    monthlyCost: (59100 / 3).toFixed(2),
    nextBatch: "Starting December 2024",
    synopsis:
      "Focuses on equipping employees with cutting-edge tools, frameworks, and methodologies aligned with industry trends and organizational needs. Ideal for companies seeking team-wide training in a specific skill set.",
    corporate: true,
    formUrl: 'https://forms.gle/e3mGsUFGx4HLdJpa9'
  },
  {
    title: "SAFe® Agile Training",
    icon: "Chat",
    duration: "3 months",
    originalPrice: 75000,
    price: 59100,
    originalMonthlyCost: (75000 / 3).toFixed(2),
    monthlyCost: (59100 / 3).toFixed(2),
    nextBatch: "Starting December 2024",
    synopsis:
      "Learn Scaled Agile Framework (SAFe®), empowering professionals to implement Agile practices across the enterprise efficiently and effectively. LilaQ AI Academy’s SAFe® Agile Training enables organizations need to scale agility beyond teams to stay competitive and deliver value faster.",
    corporate: true,
    'formUrl': 'https://forms.gle/e3mGsUFGx4HLdJpa9'
  },
  {
    title: "On Site Campus Trainings",
    icon: "Chat",
    duration: "3 months",
    originalPrice: 75000,
    price: 59100,
    originalMonthlyCost: (75000 / 3).toFixed(2),
    monthlyCost: (59100 / 3).toFixed(2),
    nextBatch: "Starting December 2024",
    synopsis:
      "These programs are designed to address specific needs of educational institutions and organizations by delivering high-quality training in technical skills, leadership development, and career-oriented courses.",
    campus: true,
    formUrl: 'https://forms.gle/dF1WMKDVy7DCiUzL7'
  },
  
  {
    title: "Mastering Cloud Resources (Azure & AWS)",
    icon: "Cloud",
    price: 45000,
    duration: "3 months",
    originalPrice: 55000,
    originalMonthlyCost: (55000 / 3).toFixed(2),
    monthlyCost: (44500 / 3).toFixed(2),
    nextBatch: "Starting December 2024",
    synopsis:
      "Learn cloud infrastructure, DevOps practices, and CI/CD pipeline design for scalable solutions.",
  },
  // {
  //   title: "Scaled Agile Framework (SAFe)",
  //   icon: "DeveloperBoard",
  //   price: 34500,
  //   duration: "3 months",
  //   originalPrice: 50000,
  //   originalMonthlyCost: (50000 / 3).toFixed(2),
  //   monthlyCost: (34500 / 3).toFixed(2),
  //   nextBatch: "Starting December 2024",
  //   synopsis:
  //     "Become proficient in implementing SAFe principles for large-scale Agile team coordination.",
  // },
  {
    title: "Software Testing Training - Manual & Automation",
    icon: "DesignServices",
    price: 34500,
    duration: "3 months",
    originalPrice: 40000,
    originalMonthlyCost: (40000 / 3).toFixed(2),
    monthlyCost: (34500 / 3).toFixed(2),
    nextBatch: "Starting December 2024",
    synopsis:
      "Gain expertise in software testing with hands-on experience in both manual and automated techniques to ensure software quality and reliability.",
  },
  {
    title: "Mastering UI/UX",
    icon: "DesignServices",
    price: 34500,
    duration: "3 months",
    originalPrice: 45000,
    originalMonthlyCost: (45000 / 3).toFixed(2),
    monthlyCost: (34500 / 3).toFixed(2),
    nextBatch: "Starting December 2024",
    synopsis:
      "Design intuitive user interfaces and experiences with tools like Figma, Adobe XD, and Sketch.",
  },
  {
    title: "Flutter Development",
    icon: "MobileFriendly",
    price: 32700,
    // price: 1,
    duration: "3 months",
    originalPrice: 42000,
    originalMonthlyCost: (42000 / 3).toFixed(2),
    monthlyCost: (37000 / 3).toFixed(2),
    nextBatch: "Starting December 2024",
    synopsis:
      "Build fast, beautiful, and cross-platform mobile apps with Dart and Flutter.",
  },
];

const CoursesPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  // const [activeIndex, setActiveIndex] = useState(Math.floor(courses.length / 2));
  const [activeIndex, setActiveIndex] = useState(2);

  const handlePrev = () => {
    setActiveIndex((prev) => (prev === 0 ? courses.length - 1 : prev - 1));
  };

  const handleNext = () => {
    setActiveIndex((prev) => (prev === courses.length - 1 ? 0 : prev + 1));
  };

  const [openStepper, setOpenStepper] = useState(false);
  const handleRegisterCourse = () => setOpenStepper(true);

  return (
    <>
      <Dialog
        open={openStepper}
        onClose={() => setOpenStepper(false)}
        fullWidth
        maxWidth="sm"
      >
        <RegistrationStepper
          onClose={() => setOpenStepper(false)}
          initialCourse={courses[activeIndex].title}
          amount={courses[activeIndex].price}
        />
      </Dialog>
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          backgroundColor: '#000',
          minHeight: '100vh',
          overflow: 'hidden',
        }}
      >

        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            overflow: 'hidden',
            zIndex: 0,
            '&::after': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.85)',
            }
          }}
        >
          <video
            autoPlay
            loop
            muted
            playsInline
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <source src={backgroundVideo} type="video/mp4" />
          </video>
        </Box>


        <Box
          sx={{
            position: 'relative',
            zIndex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 4,
            minHeight: '100vh',
            pt: 4,
            px: { xs: 1, sm: 2, md: 4 },
          }}
        >
          <Typography
            variant="h3"
            align="center"
            sx={{
              color: 'white',
              fontWeight: 'bold',
              textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
              mt: 10,
              // mb: 1,   
              fontSize: { xs: '1.8rem', sm: '2.2rem', md: '2.5rem' },
            }}
          >
            Explore Courses at LilaQ Academy
          </Typography>


          <Box
            sx={{
              width: '100%',
              maxWidth: '1400px',
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              my: 4,
              height: isMobile ? 'auto' : { xs: '700px', md: '600px' },
            }}
          >
            {isMobile ? (

              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 2,
                  
                }}
              >
                <Card
                  sx={{
                    width: '90%',
                    maxWidth: '400px',
                    background: 'linear-gradient(to bottom, #321d60, #1c2f69, #043c6c, #09476b, #225167)',
                    backdropFilter: 'blur(10px)',
                    borderRadius: '65px',

                    
                  }}
                >
                  {courses[activeIndex].corporate?
                      <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '60%',
                        height: '4%',
                        // backgroundColor: 'rgba(0,0,0,0.5)',
                        // backgroundColor: 'rgba(176, 144, 61)',
                        // zIndex: -10,
                        display: 'flex',
                        justifyContent: 'center',
                        backgroundColor: '#efbf04',
                        color: 'black',
                        fontSize: '0.4rem',
                        // fontWeight: 'bold',
                        textAlign: 'center',
                        py: 1,
                        borderTopLeftRadius: '65px', // Matches the card's top radius
                        // borderTopRightRadius: '65px', // Matches the card's top radius
                        borderBottomRightRadius: '65px',
                        zIndex: 20,
                        textTransform: 'uppercase',
                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)',
                      }}
                    >
                      <Typography sx={{fontWeight: 700, 
                        fontFamily: '"Montserrat", sans-serif',
                        fontSize: '0.6rem'
                      }}
                        >Corporate Program</Typography>
                    </Box>:
                    courses[activeIndex].campus?
                    <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '60%',
                      height: '4%',
                      // backgroundColor: 'rgba(0,0,0,0.5)',
                      // backgroundColor: 'rgba(176, 144, 61)',
                      // zIndex: -10,
                      display: 'flex',
                      justifyContent: 'center',
                      backgroundColor: '#4CAF50',
                      color: 'black',
                      fontSize: '0.6rem',
                      // fontWeight: 'bold',
                      textAlign: 'center',
                      py: 1,
                      borderTopLeftRadius: '65px', // Matches the card's top radius
                      // borderTopRightRadius: '65px', // Matches the card's top radius
                      borderBottomRightRadius: '65px',
                      zIndex: 20,
                      textTransform: 'uppercase',
                      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)',
                      
                    }}
                  >
                    <Typography sx={{fontWeight: 700, 
                      fontFamily: '"Montserrat", sans-serif',
                      fontSize: '0.6rem'
                    }}
                      >Institutional Program</Typography>
                  </Box>:
                    <></>}
                  
                  <CardContent sx={{ p: 4, textAlign: 'center' }}>
                    
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2, mb: 3 }}>
                      <Icon fontSize="large" sx={{ color: '#5a67d8' }} />
                      <Typography
                        variant="h5"
                        sx={{
                          color: 'white',
                          fontSize: '1.2rem',
                          p:1
                        }}
                      >
                        {courses[activeIndex].title}
                      </Typography>
                    </Box>
                    
                    {courses[activeIndex].corporate | courses[activeIndex].campus? <></>:
                    <Typography
                      sx={{
                        color: '#cbd5e0',
                        mb: 2,
                        fontSize: '1rem',
                      }}
                    >
                      {courses[activeIndex].nextBatch}
                    </Typography>}
                    <Typography
                      variant="body1"
                      sx={{
                        color: 'white',
                        fontSize: '1rem',
                        lineHeight: 1.6,
                      }}
                    >
                      {courses[activeIndex].synopsis}
                    </Typography>

                    {courses[activeIndex].corporate | courses[activeIndex].campus? <></>:
                    <Typography sx={{ color: '#cbd5e0', mt: 3 }}>
                          Duration: <strong>{courses[activeIndex].duration}</strong>
                        </Typography>}

                        {/* <Typography sx={{ color: '#cbd5e0', mt: 1 }}>
                          Cost per Month: <strong>₹{courses[activeIndex].monthlyCost}</strong>
                        </Typography> */}
                        {courses[activeIndex].corporate | courses[activeIndex].campus? <></>:
                        <Typography sx={{ color: '#65f565', mt: 2, fontSize: '1.2rem' }}>
                          <span style={{ color: '#cbd5e0' }}>Course Fee: </span>
                          <span style={{ textDecoration: 'line-through', color: '#cbd5e0' }}>
                          ₹{courses[activeIndex].originalPrice}
                          </span>
                          {' '}<strong>₹{courses[activeIndex].price}</strong>
                        </Typography>}
                  </CardContent>
                  
                  <CardActions sx={{ p: 4, gap: 2, flexDirection: 'column' }}>
                  {courses[activeIndex].corporate?
                       <Button
                          onClick={handleQuoteCorporate}
                          // fullWidth
                          variant="contained"
                          sx={{
                            background: 'linear-gradient(135deg, #6b46c1, #3182ce)',
                            py: 1.5,
                            '&:hover': {
                              background: 'linear-gradient(135deg, #3182ce, #6b46c1)',
                            },
                          }}
                        >
                          Request a Quote
                        </Button>
                      : courses[activeIndex].campus?
                      <Button
                          onClick={handleQuoteCampus}
                          // fullWidth
                          variant="contained"
                          sx={{
                            background: 'linear-gradient(135deg, #6b46c1, #3182ce)',
                            py: 1.5,
                            '&:hover': {
                              background: 'linear-gradient(135deg, #3182ce, #6b46c1)',
                            },
                          }}
                        >
                          Request a Quote
                        </Button>:
                    <Button
                      // fullWidth
                      variant="contained"
                      sx={{
                        background: 'linear-gradient(135deg, #6b46c1, #3182ce)',
                        py: 1.5,
                        mb: 1,
                        '&:hover': {
                          background: 'linear-gradient(135deg, #3182ce, #6b46c1)',
                        },
                      }}
                      onClick={handleRegisterCourse}
                    >
                      Enroll Now
                    </Button>}
                    
                    {/* <Button
                      fullWidth
                      variant="outlined"
                      sx={{
                        color: 'white',
                        borderColor: 'rgba(255, 255, 255, 0.5)',
                        py: 1.5,
                        '&:hover': {
                          borderColor: 'white',
                        },
                      }}
                      onClick={handleRegisterClick}
                    >
                      Book Demo
                    </Button> */}
                  </CardActions>
                </Card>

                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
                  <IconButton onClick={handlePrev} sx={{ color: 'white' }}>
                    <ChevronLeftIcon />
                  </IconButton>
                  <Typography sx={{ color: 'white' }}>
                    {activeIndex + 1} / {courses.length}
                  </Typography>
                  <IconButton onClick={handleNext} sx={{ color: 'white' }}>
                    <ChevronRightIcon />
                  </IconButton>
                </Box>
              </Box>
            ) : (

              <Box
                sx={{
                  position: 'relative',
                  width: '100%',
                  height: '100%',
                  transformStyle: 'preserve-3d',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  
                }}
              >
                

                <IconButton
                  onClick={handlePrev}
                  sx={{
                    position: 'absolute',
                    left: { xs: '10px', md: '50px' },
                    zIndex: 10,
                    color: 'white',
                    bgcolor: 'rgba(0,0,0,0.3)',
                    '&:hover': { bgcolor: 'rgba(0,0,0,0.5)' },
                    width: '48px',
                    height: '48px',
                  }}
                >
                  <ChevronLeftIcon fontSize="large" />
                </IconButton>

                <IconButton
                  onClick={handleNext}
                  sx={{
                    position: 'absolute',
                    right: { xs: '10px', md: '50px' },
                    zIndex: 10,
                    color: 'white',
                    bgcolor: 'rgba(0,0,0,0.3)',
                    '&:hover': { bgcolor: 'rgba(0,0,0,0.5)' },
                    width: '48px',
                    height: '48px',
                  }}
                >
                  <ChevronRightIcon fontSize="large" />
                </IconButton>

                {courses.map((course, index) => {
                  const diff = index - activeIndex;
                  const isActive = index === activeIndex;
                  const absDistance = Math.abs(diff);

                  const adjustedDiff =
                    diff < -Math.floor(courses.length / 2)
                      ? diff + courses.length
                      : diff > Math.floor(courses.length / 2)
                        ? diff - courses.length
                        : diff;

                  if (absDistance > 2) return null;

                  return (
                    <Card
                      key={index}
                      sx={{
                        position: 'absolute',
                        width: { xs: '80%', sm: '60%', md: '50%' },
                        height: isActive ? { xs: '550px', md: '500px' } : '450px',
                        borderRadius: '65px',
                        background: 'linear-gradient(to bottom, #321d60, #1c2f69, #043c6c, #09476b, #225167)',
                        backdropFilter: 'blur(10px)',
                        transition: 'all 0.6s cubic-bezier(0.23, 1, 0.32, 1)',
                        opacity: 1 - absDistance * 0.3,
                        transform: `
                        translateX(${adjustedDiff * 60}%) 
                        translateZ(${isActive ? 0 : -200 * Math.abs(adjustedDiff)}px) 
                        scale(${1 - Math.abs(adjustedDiff) * 0.1}) 
                        rotateY(${adjustedDiff * 10}deg)
                      `,
                        pointerEvents: isActive ? 'auto' : 'none',
                        zIndex: 10 - absDistance,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {course.corporate?
                      <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '40%',
                        height: '4%',
                        // backgroundColor: 'rgba(0,0,0,0.5)',
                        // backgroundColor: 'rgba(176, 144, 61)',
                        // zIndex: -10,
                        display: 'flex',
                        justifyContent: 'center',
                        backgroundColor: '#efbf04',
                        color: 'black',
                        fontSize: '0.6rem',
                        // fontWeight: 'bold',
                        textAlign: 'center',
                        py: 1,
                        borderTopLeftRadius: '65px', // Matches the card's top radius
                        // borderTopRightRadius: '65px', // Matches the card's top radius
                        borderBottomRightRadius: '65px',
                        zIndex: 20,
                        textTransform: 'uppercase',
                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)',
                      }}
                    >
                      <Typography sx={{fontWeight: 700, 
                        fontFamily: '"Montserrat", sans-serif',
                        fontSize: '0.9rem'
                      }}
                        >Corporate Program</Typography>
                    </Box>:course.campus ? 
                    <Box
                    sx={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '40%',
                      height: '4%',
                      // backgroundColor: 'rgba(0,0,0,0.5)',
                      // backgroundColor: 'rgba(176, 144, 61)',
                      // zIndex: -10,
                      display: 'flex',
                      justifyContent: 'center',
                      backgroundColor: '#4CAF50',
                      color: 'black',
                      fontSize: '0.6rem',
                      // fontWeight: 'bold',
                      textAlign: 'center',
                      py: 1,
                      borderTopLeftRadius: '65px', // Matches the card's top radius
                      // borderTopRightRadius: '65px', // Matches the card's top radius
                      borderBottomRightRadius: '65px',
                      zIndex: 20,
                      textTransform: 'uppercase',
                      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.2)',
                      
                    }}
                  >
                    <Typography sx={{fontWeight: 700, 
                      fontFamily: '"Montserrat", sans-serif',
                      fontSize: '0.8rem'
                    }}
                      >Institutional Program</Typography>
                  </Box>: <></>
                    }
                      
                      <CardContent sx={{ flexGrow: 1, p: 4, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3, justifyContent: 'center' }}>
                          <Icon fontSize="large" sx={{ color: '#5a67d8' }} />
                          <Typography
                            variant="h4"
                            sx={{
                              color: 'white',
                              fontSize: { xs: '1.5rem', md: '2rem' },
                            }}
                          >
                            {course.title}
                          </Typography>
                        </Box>
                        {course.corporate | course.campus?
                        <></>:
                        <Typography
                          sx={{
                            color: '#cbd5e0',
                            mb: 2,
                            fontSize: '1.1rem',
                            textAlign: 'center',
                          }}
                        >
                          {course.nextBatch}
                        </Typography>
                        }
                        <Typography
                          variant="body1"
                          sx={{
                            color: 'white',
                            fontSize: '1.1rem',
                            lineHeight: 1.8,
                            textAlign: 'center',
                          }}
                        >
                          {course.synopsis}
                        </Typography>

                        {course.corporate | course.campus? 
                        <></>:
                        <Typography sx={{ color: '#cbd5e0', mt: 3 }}>
                          Duration: <strong>{course.duration} + micro-internship</strong>
                        </Typography>}
                        {/* <Typography sx={{ color: '#cbd5e0', mt: 1 }}>
                          Cost per Month: <strong>₹{courses[activeIndex].monthlyCost}</strong>
                        </Typography> */}
                        {course.corporate | course.campus? 
                        <></>:
                        <Typography sx={{ color: '#65f565', mt: 2, fontSize: '1.2rem' }}>
                          <span style={{ color: '#cbd5e0' }}>Course Fee: </span>
                          <span style={{ textDecoration: 'line-through', color: '#cbd5e0' }}>
                          ₹{course.originalPrice}
                          </span>
                          {' '}<strong>₹{course.price}</strong>
                        </Typography>}
                      </CardContent>
                      <CardActions sx={{ p: 4, gap: 2, display: 'flex', justifyContent: 'center' }}>

                      {course.corporate?
                       <Button
                          onClick={handleQuoteCorporate}
                          fullWidth
                          variant="contained"
                          sx={{
                            background: 'linear-gradient(135deg, #6b46c1, #3182ce)',
                            py: 1.5,
                            '&:hover': {
                              background: 'linear-gradient(135deg, #3182ce, #6b46c1)',
                            },
                          }}
                        >
                          Request a Quote
                        </Button>
                      : course.campus?
                      <Button
                          onClick={handleQuoteCampus}
                          fullWidth
                          variant="contained"
                          sx={{
                            background: 'linear-gradient(135deg, #6b46c1, #3182ce)',
                            py: 1.5,
                            '&:hover': {
                              background: 'linear-gradient(135deg, #3182ce, #6b46c1)',
                            },
                          }}
                        >
                          Request a Quote
                        </Button> :
                        <Button
                          onClick={handleRegisterCourse}
                          fullWidth
                          variant="contained"
                          sx={{
                            background: 'linear-gradient(135deg, #6b46c1, #3182ce)',
                            py: 1.5,
                            '&:hover': {
                              background: 'linear-gradient(135deg, #3182ce, #6b46c1)',
                            },
                          }}
                        >
                          Enroll Now
                        </Button>}
                        {/* <Button
                          onClick={handleRegisterClick}
                          fullWidth
                          variant="outlined"
                          sx={{
                            color: 'white',
                            borderColor: 'rgba(255, 255, 255, 0.5)',
                            py: 1.5,
                            '&:hover': {
                              borderColor: 'white',
                            },
                          }}
                        >
                          Book Free Demo
                        </Button> */}
                      </CardActions>
                    </Card>
                  );
                })}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CoursesPage;